import { useReducer, useMemo } from 'react';
import { add, sub, format, isFuture } from 'date-fns';
import getTodayDate from 'libs/date/getTodayDate';

function reducer(state, { type, payload }) {
  switch (type) {
    case 'forward':
      if (!state.isAbleToFuture && isFuture(new Date(state.date))) {
        return state;
      }
      return { ...state, date: format(add(new Date(state.date), { days: 1 }), state.formatStr) };
    case 'backward':
      return { ...state, date: format(sub(new Date(state.date), { days: 1 }), state.formatStr) };
    case 'toToday':
      return { ...state, date: getTodayDate() };
    case 'setDate':
      if (!state.isAbleToFuture && isFuture(new Date(payload))) {
        return state;
      }
      return { ...state, date: payload };
    default:
      return state;
  }
}

export default function useDate(config) {
  const defaultDate = config?.defaultDate || getTodayDate();
  const isAbleToFuture = config?.isAbleToFuture || true;
  const formatStr = config?.format || 'yyyy-MM-dd';
  const subOpt = Boolean(config?.sub) && config.sub;
  const addOpt = Boolean(config?.add) && config.add;

  const [{ date }, dispatch] = useReducer(
    reducer,
    {
      date: defaultDate,
      isAbleToFuture,
      formatStr,
    },
    state => {
      if (sub) {
        return {
          ...state,
          date: format(sub(new Date(state.date), { ...subOpt }), formatStr),
        };
      }

      if (add) {
        return {
          ...state,
          date: format(add(new Date(state.date), { ...addOpt }), formatStr),
        };
      }

      return state;
    },
  );

  const value = useMemo(() => [date, dispatch], [date]);

  return value;
}
