import { useState, useEffect } from 'react';
let timeout = null;

export default function useTimer() {
  const [activate, setActivate] = useState(true);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    //stop timer
    if (activate === false) {
      return;
    }

    timeout = setTimeout(() => setTimer(prev => prev + 1), 1000);

    return () => clearTimeout(timeout);
  }, [timer, activate]);

  const handleResetTimer = () => setTimer(0);
  const handlePauseTimer = () => setActivate(false);

  return [timer, handleResetTimer, handlePauseTimer];
}
