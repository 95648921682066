import { Chip, Box } from '@mui/material';

const WorkingChip = () => {
  return (
    <Box
      sx={{
        width: '68.45px',
        height: '32px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          '@keyframes rotate-360': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' },
          },
          animation: 'rotate-360 linear 2s infinite',
          width: '100%',
          height: '150%',
          '& span': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'relative',
            transform: 'translate(-50%,-50%)',
            '&:after': {
              display: 'block',
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: '100%',
            },
            '&:first-of-type': {
              backgroundColor: 'warning.light',
              '&:after': {
                backgroundColor: 'warning.main',
              },
            },
            '&:last-child': {
              backgroundColor: 'warning.contrastText',
              '&:after': {
                backgroundColor: 'warning.dark',
              },
            },
          },
        }}
      >
        <span></span>
        <span></span>
      </Box>
      <Chip
        label="看診中"
        color="warning"
        sx={{
          width: 'calc(100% - 3px)',
          height: 'calc(100% - 3px)',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      />
    </Box>
  );
};

export default function StatusChip({ type }) {
  switch (type) {
    case '尚未報到':
      return <Chip label="尚未報到" color="info" />;
    case '候診中':
      return <Chip label="候診中" color="primary" />;
    // case "working":
    //   return <WorkingChip />
    case '看診中':
      return <Chip label="看診中" color="warning" />;
    case '未結帳':
      return <Chip label="未結帳" variant="outlined" color="error" />;
    case '已結帳':
      return <Chip label="已結帳" color="success" />;
    // case "完診":
    //   return <Chip label="完診" color="secondary" />
    // case "idle":
    //   return <Chip label="等待中" variant="outlined" />
    default:
      return <Chip label="狀態錯誤" color="error" />;
  }
}
