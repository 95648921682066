import { useCallback } from "react";
import { useRouter } from "next/router";
import { Stack, Divider, Skeleton, Alert } from "@mui/material";
import { size } from "lodash";
import { format } from "date-fns";

import PATH from "libs/path";
import { toOneMember } from "libs/navPath";
import QueueItemTitle from "features/Reservation/QueueBoard/QueueItemTitle";
import QueueItem from "features/Reservation/QueueBoard/QueueItem";
import Brand from "components/Brand/Brand";
import GenderIcon from "components/UI/GenderIcon";
import FallBackPetAvatar from "components/Avatar/FallBackPetAvatar";

export default function QueueWaitList({ list, showCheckInAction, isDisplayGroup, onCancel, onMutate, isFuture }) {
  const router = useRouter();
  const handleToMedicalRecordPage = useCallback(petId => {
    router.push(PATH.toOnePetMedicalRecord(petId));
    //eslint-disable-next-line
  }, []);

  const handleToPetPage = useCallback(petId => {
    router.push(PATH.toOnePet(petId));
    //eslint-disable-next-line
  }, []);
  const handleToMemberPage = useCallback(memberId => {
    router.push(toOneMember(memberId));
    //eslint-disable-next-line
  }, []);

  return (
    <Stack sx={{ p: 1, position: "relative" }}>
      <QueueItemTitle key="queueItemTitle" showCheckInAction={showCheckInAction} />

      <Stack spacing={1.2}>
        {!list &&
          Array.from({ length: 9 }).map((_, i) => (
            <Skeleton key={i} variant="rectangular" height={48} animation="wave" sx={{ borderRadius: 2 }} />
          ))}
        {size(list) === 0 && (
          <Alert severity="info" sx={{ mt: 1 }}>
            今日尚無預約以及候診資訊
          </Alert>
        )}
        {!isDisplayGroup &&
          list &&
          list.map(item => (
            <QueueItem
              isAllDay={item.calendar.allDay === "true"}
              petId={item.petIdentityId}
              key={item.id}
              reservationId={item.id}
              reserveTime={format(new Date(item.calendar.startDate), "HH:mm")}
              petCode={`${item.pet.petCode} | ${item.pet.originPetCode || "--"}`}
              isReserve={item.isReserve}
              petName={item?.pet?.petName}
              memberName={item.member.memberName}
              doctorName={item?.employee?.realName}
              note={item.description}
              status={item.status}
              hasMedicine={false}
              isCheckIn={item.isComplete}
              showCheckInAction={showCheckInAction}
              onCancel={onCancel.bind(null, item.id)}
              modifyTime={item.modifyTime ?? item.createTime}
              item={item}
              onMutate={onMutate}
              isFuture={isFuture}
              onToMedicalRecordPage={handleToMedicalRecordPage.bind(null, item.petIdentityId)}
              onToPetPage={handleToPetPage.bind(null, item.petIdentityId)}
              onToMemberPage={handleToMemberPage.bind(null, item.memberId)}
              petTypeName={item?.pet?.petType?.typeName || ""}
              petIcon={<FallBackPetAvatar petType={item?.pet?.petType?.typeName} sx={{ backgroundColor: "white" }} />}
              genderIcon={<GenderIcon gender={item?.pet?.gender?.genderName} />}
            />
          ))}
        {isDisplayGroup &&
          list &&
          list.map(doctor => (
            <>
              <Brand fontSize={16}>{doctor.realName ? `醫師 ${doctor.realName}` : "未分配醫師"}</Brand>

              {doctor.reservationList.map(item => (
                <QueueItem
                  isAllDay={item.calendar.allDay === "true"}
                  petId={item.petIdentityId}
                  key={item.id}
                  reservationId={item.id}
                  reserveTime={format(new Date(item.calendar.startDate), "HH:mm")}
                  petCode={`${item.pet.petCode} | ${item.pet.originPetCode || "--"}`}
                  isReserve={item.isReserve}
                  petName={item?.pet?.petName}
                  memberName={item.member.memberName}
                  doctorName={item?.employee?.realName}
                  note={item.description}
                  status={item.status}
                  hasMedicine={false}
                  isCheckIn={item.isComplete}
                  showCheckInAction={showCheckInAction}
                  onCancel={onCancel.bind(null, item.id)}
                  modifyTime={item.modifyTime ?? item.createTime}
                  item={item}
                  onMutate={onMutate}
                  isFuture={isFuture}
                  onToMedicalRecordPage={handleToMedicalRecordPage.bind(null, item.petIdentityId)}
                  onToPetPage={handleToPetPage.bind(null, item.petIdentityId)}
                  onToMemberPage={handleToMemberPage.bind(null, item.memberId)}
                  petTypeName={item?.pet?.petType?.typeName || ""}
                  petIcon={<FallBackPetAvatar petType={item?.pet?.petType?.typeName} sx={{ backgroundColor: "white" }} />}
                  genderIcon={<GenderIcon gender={item?.pet?.gender?.genderName} />}
                />
              ))}
              <Divider sx={{ mt: 0.5, mb: 1 }} />
            </>
          ))}
      </Stack>
    </Stack>
  );
}
