import { Stack } from "@mui/material";
import StatusChip from "features/Reservation/QueueBoard/StatusChip";
export default function ChipStatusHelper() {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={1}>
      <StatusChip type="尚未報到" />
      <StatusChip type="候診中" />
      <StatusChip type="看診中" />
      {/* <StatusChip type="完診" /> */}
      <StatusChip type="未結帳" />
      <StatusChip type="已結帳" />
    </Stack>
  );
}
