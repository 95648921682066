import { useRouter } from 'next/router';
import { Box, Button, Stack, Typography } from '@mui/material';
import Icon from 'components/Icon/index';

export default function CalendarNav() {
  const router = useRouter();
  return (
    <Button
      onClick={() => router.push('/calendar')}
      color="success"
      variant="contained"
      sx={{
        height: '200px',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '10px',
          backgroundColor: 'white',
          position: 'absolute',
          bottom: 0,
        }}
      />
      <Stack alignItems="center" justifyContent="center" sx={{ position: 'absolute', top: 70, right: 5 }}>
        <Icon iconName="calendar" sx={{ fontSize: '80px', color: '#FFFFFF' }} />
      </Stack>
      <Typography
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          color: 'white',
          fontSize: '36px',
          fontWeight: 'bolder',
        }}
      >
        行事曆
      </Typography>
    </Button>
  );
}
