import { Stack, Grid } from "@mui/material";
import { MainPageLayout } from "components/Layout";
import QueueDashBoard from "features/Reservation/QueueBoard";
import FirstVisitDialog from "components/Home/FirstVisitDialog";
import CalendarNav from "components/Home/CalendarNav";

export default function HomePage(props) {
  return (
    <Grid container sx={{ flexGrow: 1, height: "calc(100vh - 90px)", pb: 1 }} columnSpacing={1}>
      <Grid item xs={12} md={10}>
        <QueueDashBoard />
      </Grid>
      <Grid item xs={12} md>
        <Stack spacing={1} flexGrow="1" height="100%">
          <CalendarNav />
          <FirstVisitDialog />
        </Stack>
      </Grid>
    </Grid>
  );
}

HomePage.layout = p => <MainPageLayout>{p}</MainPageLayout>;
