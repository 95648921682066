import { Grid, Typography } from "@mui/material";

export default function QueueItemTitle({ showCheckInAction }) {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        px: 0.5,
        "& p": { fontWeight: "bolder", fontSize: "0.9rem", color: "text.secondary" },
        borderBottom: "1px solid #00000025",
        background: "#FFF",
      }}
    >
      <Grid item xs={1}>
        <Typography>預約時間</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>寵物 / 飼主</Typography>
      </Grid>
      {/* <Grid item xs>
        <Typography>飼主</Typography>
      </Grid> */}
      <Grid item xs={2}>
        <Typography>醫生</Typography>
      </Grid>

      <Grid item xs={1} textAlign="center">
        <Typography>狀態</Typography>
      </Grid>
      {/* <Grid item xs={1} textAlign="center">
        <Typography>藥品</Typography>
      </Grid> */}
      {showCheckInAction && (
        <Grid item xs={2} textAlign="center">
          <Typography>報到</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography>備註</Typography>
      </Grid>
    </Grid>
  );
}
