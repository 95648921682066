import { Avatar } from '@mui/material';

import dogIcon from 'public/images/dog_icon.png';
import catIcon from 'public/images/cat_icon.png';
import pawIcon from 'public/images/paw_icon.png';

function identityPetType(petType) {
  switch (petType) {
    case '狗':
      return dogIcon;
    case '貓':
      return catIcon;
    default:
      return pawIcon;
  }
}

export default function FallBackPetAvatar({ petType, sx }) {
  return <Avatar src={identityPetType(petType).src} sx={sx} />;
}
