import { useEffect } from 'react';
import useTimer from 'libs/hooks/useTimer';

let timeout = null;

export default function Counter({ delay, reservationData }) {
  const [timer, handleResetTimer] = useTimer();

  useEffect(() => {
    if (delay === timer * 1000) {
      handleResetTimer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if (reservationData) {
      handleResetTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationData]);

  return timer;
}
