import { useQRCode } from 'next-qrcode';
import { styled } from '@mui/system';
import { Button, Dialog, DialogTitle, DialogContent, Stack, DialogContentText } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import useToggle from 'libs/hooks/useToggle';
import { useHospDataService } from 'service';

const QRcodeButton = styled(Button)({
  height: '100%',
  width: '100%',
  fontSize: '2rem',
  fontWeight: 'bold',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      width: 80,
      height: 80,
    },
  },
});

const QR_CONFIG = {
  level: 'M',
  margin: 3,
  scale: 1,
  width: 340,
  color: {
    dark: '#000000',
    light: '#E5E5E500',
  },
};

export default function FirstVisitDialog({ ButtonComponent }) {
  const { Canvas: CanvasQrCode } = useQRCode();
  const [open, toggle] = useToggle();
  const { useConfig } = useHospDataService();
  const { data: configData } = useConfig();

  //temporaily
  const firstVisitURL = `${process.env.API_URL || process.env.NEXT_PUBLIC_API_URL}/api/member/totp?custNo=${configData?.custNo}`;

  return (
    <>
      <QRcodeButton color="primary" variant="contained" startIcon={<QrCodeIcon />} onClick={toggle}>
        初診QRCODE
      </QRcodeButton>
      <Dialog open={open} onClose={toggle.toFalse} fullWidth maxWidth="sm">
        <DialogTitle>初診QRCODE</DialogTitle>
        <DialogContent>
          <DialogContentText>可右鍵另存圖片，下載列印使用</DialogContentText>
          <Stack alignItems="center" justifyContent="center" mt={1}>
            {process.env.NODE_ENV === 'development' && firstVisitURL}
            {firstVisitURL && <CanvasQrCode text={firstVisitURL} options={QR_CONFIG} />}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
