import { useEffect, useRef } from "react";
import { format, isFuture, isToday } from "date-fns";
import { Stack, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, Button, LinearProgress, Box } from "@mui/material";
import { useReservationService, useOldPetService } from "service";
import Icon from "components/Icons";
import ReservingDialog from "features/Reservation/ReservingDialog";
import useToggle from "libs/hooks/useToggle";
import useSubmit from "libs/hooks/useSubmit";
import ConfirmDialog from "components/ConfirmDialog/index";
import { MedicalRecordActionButton, InfoActionButton, PetInfoActionButton, MemberInfoActionButton } from "features/ActionButton";
import GenderIcon from "components/UI/GenderIcon";

export default function ReservationDetailDialog({ isOpen, reserveId, onClose, onDelete }) {
  const initRef = useRef();
  const { useOnePet } = useOldPetService();
  const { useOneReservation, deleteReservation } = useReservationService();
  const [isPending, handleSubmit] = useSubmit();
  const [open, toggle] = useToggle(isOpen);

  const { data: detail, isLoading, mutate } = useOneReservation(reserveId, null, open);
  const { data: pet } = useOnePet(detail && detail?.pet?.id);

  //controlled prop `isOpen`
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }

    if (isOpen) {
      toggle.toTrue();
    } else {
      toggle.toFalse();
    }
  }, [isOpen]);

  const handleClose = () => {
    toggle();
    onClose?.();
  };

  const isReservationToday = detail && isToday(new Date(detail.calendar.startDate));
  const isReservationFuture = detail && isFuture(new Date(detail.calendar.startDate));
  const isDeletable = detail && (detail.status === "尚未報到" || detail.status === "候診中");

  const handleDelete = () => {
    handleSubmit(deleteReservation.bind(null, detail.id), onDelete, handleClose);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>預約詳細資訊</DialogTitle>
        {(isLoading || !pet) && <LinearProgress />}
        <Stack direction="row" alignItems="center" sx={{ position: "absolute", right: 24, top: 16 }}>
          {detail && (
            <>
              <MedicalRecordActionButton petId={detail.pet.id} />
              <PetInfoActionButton petId={detail.pet.id} />
              <MemberInfoActionButton memberId={detail.member.id} />
            </>
          )}
        </Stack>
        <DialogContent sx={{ height: detail ? "440px" : "0px", transition: "height 0.2s" }}>
          {detail && pet && (
            <Stack height="100%">
              <Typography sx={{ whiteSpace: "pre-wrap" }}>狀態：{detail.status}</Typography>
              <Divider sx={{ my: 1 }} />
              <Stack direction="row" alignItems="center">
                <Typography>寵物：{detail.pet.petName}</Typography>
                <GenderIcon gender={pet.gender?.genderName} />
              </Stack>

              <Typography>類別：{pet?.petType?.typeName || "無登記"}</Typography>
              <Typography>會員：{detail.member.memberName}</Typography>
              <Typography>預約類型：{detail.manipulateType.manipulateTypeName}</Typography>
              <Typography>
                預約時間：
                {format(new Date(detail.calendar.startDate), "yyyy-MM-dd HH:mm")} -{" "}
                {format(new Date(detail.calendar.endDate), "yyyy-MM-dd HH:mm")}
              </Typography>
              <Typography>預約醫生：{detail.employeeId ? detail.employee.realName : ""}</Typography>
              <Typography>備註：{detail.description || "---"}</Typography>
              <Box flex="1" />
              <Divider sx={{ my: 1 }} />
              <Typography>建立時間：{format(new Date(detail.createTime), "yyyy-MM-dd HH:mm:ss")}</Typography>
              <Typography>建立使用者：{detail.createUserName}</Typography>
              <Typography>最近編輯時間：{format(new Date(detail.modifyTime), "yyyy-MM-dd HH:mm:ss")}</Typography>
              <Typography>最近編輯使用者：{detail.modifyUserName}</Typography>
            </Stack>
          )}
        </DialogContent>

        {(isReservationToday || isReservationFuture) && (
          <DialogActions sx={{ justifyContent: "space-around" }}>
            <ReservingDialog
              onUpdate={mutate}
              reserveId={reserveId}
              button={<Button startIcon={<Icon type="edit" color="primary" />}>編輯</Button>}
            />

            {isDeletable && (
              <ConfirmDialog onConfirm={handleDelete}>
                <Button startIcon={<Icon type="delete" />} color="error">
                  刪除
                </Button>
              </ConfirmDialog>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
