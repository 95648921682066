import { useMemo, useRef } from "react";
import { useReservationService, usePharmacyService } from "service";

const ONE_MIN = 60000; // 1 min

export default function useBrief(date) {
  const { useAllReservation } = useReservationService();
  const { useAllPharmacy } = usePharmacyService();

  const { data: dateBrief } = useAllReservation({ date, isCancel: 0 }, { refreshInterval: ONE_MIN });
  const { data: dateReserveBrief } = useAllReservation({ isReserve: 0, date, isCancel: 0 }, { refreshInterval: ONE_MIN });

  const { data: dateLiveBrief } = useAllReservation({ isReserve: 1, date, isCancel: 0 }, { refreshInterval: ONE_MIN });

  const { data: pharmacyBrief } = useAllPharmacy({ date });
  const { data: pharmacyDoneBrief } = useAllPharmacy({ date, statusList: 2 });
  const { data: pharmacyTakenBrief } = useAllPharmacy({ date, statusList: 3 });

  /* 預約 */
  const total = useRef(0);
  const reserve = useRef(0);
  const live = useRef(0);

  total.current = dateBrief?.totalCount >= 0 ? dateBrief.totalCount : total.current;
  reserve.current = dateReserveBrief?.totalCount >= 0 ? dateReserveBrief.totalCount : reserve.current;
  live.current = dateLiveBrief?.totalCount >= 0 ? dateLiveBrief.totalCount : live.current;

  /* 藥單 */
  const medTotal = useRef(0);
  const medDone = useRef(0);
  const medTaken = useRef(0);

  medTotal.current = pharmacyBrief?.totalCount >= 0 ? pharmacyBrief.totalCount : medTotal.current;
  medDone.current = pharmacyDoneBrief?.totalCount >= 0 ? pharmacyDoneBrief.totalCount : medDone.current;
  medTaken.current = pharmacyTakenBrief?.totalCount >= 0 ? pharmacyTakenBrief.totalCount : medTaken.current;

  const reservationValue = useMemo(() => {
    return {
      total: total.current,
      reserve: reserve.current,
      live: live.current,
    };
  }, [total.current, reserve.current, live.current]);

  const pharmacyValue = useMemo(() => {
    return {
      total: medTotal.current,
      done: medDone.current,
      taken: medTaken.current,
    };
  }, [medTotal.current, medDone.current, medTaken.current]);

  return [reservationValue, pharmacyValue];
}
