import { Stack, Typography, Divider } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";

import useBrief from "libs/hooks/useBrief";

export default function TodayBrief({ date }) {
  const [reservationBrief, pharmacyBrief] = useBrief(date);
  return (
    <Stack
      p={1}
      direction="row"
      spacing={2}
      sx={{
        height: "15vmin",
        "& > div": {
          borderRadius: 2,
          flexGrow: 1,
          px: 1,
          "&:not(:last-child)": {
            border: "1px solid lightgray",
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <Stack flexGrow="1" alignItems="center" justifyContent="center">
          <Typography fontSize="0.9rem" sx={{ borderBottom: "1px solid gray" }}>
            今日診量
          </Typography>
          <Typography fontSize="2.5rem">{reservationBrief.total}</Typography>
        </Stack>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Stack flexGrow="1" alignItems="center">
          <Typography fontSize="2rem">{reservationBrief.reserve}</Typography>
          <Typography fontSize="0.9rem" sx={{ borderTop: "1px solid gray" }}>
            預約
          </Typography>
        </Stack>
        <Stack flexGrow="1" alignItems="center">
          <Typography fontSize="2rem">{reservationBrief.live}</Typography>
          <Typography fontSize="0.9rem" sx={{ borderTop: "1px solid gray" }}>
            現場
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Stack flexGrow="1" alignItems="center" justifyContent="center">
          <Typography fontSize="0.9rem" sx={{ borderBottom: "1px solid gray" }}>
            今日藥單
          </Typography>
          <Typography fontSize="2.5rem">{pharmacyBrief.total}</Typography>
        </Stack>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Stack flexGrow="1" alignItems="center">
          <Typography fontSize="2rem">{pharmacyBrief.done}</Typography>
          <Typography fontSize="0.9rem" sx={{ borderTop: "1px solid gray" }}>
            已包
          </Typography>
        </Stack>
        <Stack flexGrow="1" alignItems="center">
          <Typography fontSize="2rem">{pharmacyBrief.taken}</Typography>
          <Typography fontSize="0.9rem" sx={{ borderTop: "1px solid gray" }}>
            已領
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{
          display: "none",
          position: "relative",
          overflow: "hidden",
          backgroundColor: "success.main",
          transition: "all 0.2s ",
          "&:hover": {
            cursor: "pointer",
            transform: "translateY(-3px)",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <AssignmentIcon
          sx={{
            fontWeight: "bolder",
            fontSize: "7vw",
            position: "absolute",
            color: "white",
            bottom: "-2rem",
            left: 0,
          }}
        />
        <Typography
          sx={{
            fontWeight: "bolder",
            fontSize: "1rem",
            position: "absolute",
            color: "white",
            bottom: 10,
            right: 10,
          }}
        >
          醫院公告欄
        </Typography>
      </Stack>
    </Stack>
  );
}
