import { useState } from "react";
import { Grid, Stack, Avatar, Typography, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info";

import StatusChip from "features/Reservation/QueueBoard/StatusChip";
import ConfirmDialog from "components/ConfirmDialog/index";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useReservationService } from "service";
import useToggle from "libs/hooks/useToggle";
import useSubmit from "libs/hooks/useSubmit";
import Icon from "components/Icon/index";

import ReservingDialog from "features/Reservation/ReservingDialog";
import { MedicalRecordActionButton } from "features/ActionButton";
import ReservationDetailDialog from "../ReservationDetailDialog";

export default function QueueItem({
  isAllDay,
  petId,
  reservationId,
  reserveTime,
  petCode,
  isReserve,
  petName,
  memberName,
  doctorName,
  note,
  status,
  hasMedicine,
  isCheckIn,
  showCheckInAction,
  onCancel,
  modifyTime,
  item,
  onMutate,
  isFuture,
  onToMedicalRecordPage,

  onToPetPage,
  onToMemberPage,
  petTypeName,

  petIcon,
  genderIcon,
}) {
  const { checkInReservation } = useReservationService();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [checkIn, toggle] = useToggle(status !== "尚未報到");
  const [isPending, handleSubmit] = useSubmit();

  const handleCheckIn = () => handleSubmit(checkInReservation.bind(null, reservationId), toggle);

  const handleContextMenu = event => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        px: 0.5,
        py: 0.5,
        borderRadius: 2,
        "&:hover": {
          backgroundColor: "#E5E5E550",
          transition: "all 0.2s ease",
        },
        backgroundColor: contextMenu ? "#E5E5E550" : "transparent",
      }}
      onContextMenu={handleContextMenu}
    >
      <Grid item xs={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Icon iconName={isReserve ? "walkIn" : "phone"} color="action" />
          <Typography>{isAllDay ? "全天" : reserveTime}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {petIcon}
          <Stack>
            <Stack direction="row" alignItems="center">
              <Typography>{petName}</Typography>
              {genderIcon}
              <Typography variant="body2" color="#00000060">
                {petTypeName}
              </Typography>
            </Stack>

            <Typography variant="body2">{petCode}</Typography>
            <Typography variant="body2">飼主： {memberName}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{doctorName || "尚無分配"}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={1} textAlign="center">
        {status === "候診中" ? (
          <Tooltip title={formatDistanceToNow(new Date(modifyTime))}>
            <span>
              <StatusChip type={status !== "尚未報到" ? status : checkIn ? "候診中" : "尚未報到"} />
            </span>
          </Tooltip>
        ) : (
          <StatusChip type={status !== "尚未報到" ? status : checkIn ? "候診中" : "尚未報到"} />
        )}
      </Grid>
      {showCheckInAction && (
        <Grid item xs={2} textAlign="center">
          {!isFuture && (
            <ConfirmDialog title="確認報到？" onConfirm={handleCheckIn} disabled={checkIn}>
              <Tooltip title={checkIn ? "已報到" : "尚未報到"}>
                <span>
                  <IconButton disabled={checkIn}>
                    <Icon iconName={checkIn ? "checkBox" : "uncheckBox"} color="primary" />
                  </IconButton>
                </span>
              </Tooltip>
            </ConfirmDialog>
          )}
          <ConfirmDialog title="取消預約" onConfirm={onCancel} disabled={status !== "尚未報到" && status !== "候診中"}>
            <Tooltip title={status !== "尚未報到" && status !== "候診中" ? "無法取消(已開始看診)" : "取消預約"}>
              <span>
                <IconButton disabled={status !== "尚未報到" && status !== "候診中"}>
                  <Icon iconName="cancel" color="error" />
                </IconButton>
              </span>
            </Tooltip>
          </ConfirmDialog>
          <ReservingDialog
            reserveId={reservationId}
            onUpdate={onMutate}
            button={
              <Tooltip title="編輯">
                <IconButton>
                  <Icon iconName="edit" color="secondary" />
                </IconButton>
              </Tooltip>
            }
          />
        </Grid>
      )}
      <Grid item xs sx={{ whiteSpace: "pre-wrap" }}>
        <Stack flex="1" direction="row" alignItems="center" gap={1}>
          <Typography flex="1" sx={{ wordBreak: "break-all", maxHeight: "85px", overflowY: "auto" }}>
            {note || "---"}
          </Typography>

          <Stack alignItems="center">
            <IconButton size="small" onClick={handleContextMenu}>
              <MenuIcon />
            </IconButton>
            <IconButton size="small" onClick={() => setInfoDialogOpen(true)}>
              <InfoIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      <ReservationDetailDialog reserveId={reservationId} isOpen={infoDialogOpen} onClose={() => setInfoDialogOpen(false)} />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MedicalRecordActionButton
          petId={petId}
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon iconName="medicalRecord" />
              </ListItemIcon>
              <ListItemText>查看病歷</ListItemText>
            </MenuItem>
          }
        />
        <MenuItem onClick={onToPetPage}>
          <ListItemIcon>
            <Icon iconName="pet" color="success" />
          </ListItemIcon>
          <ListItemText>查看寵物</ListItemText>
        </MenuItem>
        <MenuItem onClick={onToMemberPage}>
          <ListItemIcon>
            <Icon iconName="member" color="primary" />
          </ListItemIcon>
          <ListItemText>查看飼主</ListItemText>
        </MenuItem>
      </Menu>
    </Grid>
  );
}
