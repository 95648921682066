import { isFuture, isPast } from "date-fns";
import { pick } from "lodash";
import { useState, useMemo, useCallback } from "react";
import { useReservationService, useUserService, useManipulateType } from "service";
import useToggle from "libs/hooks/useToggle";
import useDate from "libs/hooks/useDate";
import getTodayDate from "libs/date/getTodayDate";

import { styled } from "@mui/system";
import { Tabs, Tab, Box, Stack, Typography, Button, Input, FormControlLabel, Checkbox } from "@mui/material";
import QueueWaitList from "features/Reservation/QueueBoard/QueueWaitList";
import TodayBrief from "features/Reservation/QueueBoard/TodayBrief";
import ChipStatusHelper from "features/Reservation/QueueBoard/ChipStatusHelper";
import TooltipIconButton from "components/UI/Button/TooltipIconButton";
import Counter from "components/UI/Counter";
import Icon from "components/Icons";
import useSubmit from "libs/hooks/useSubmit";
import ReservingDialog from "features/Reservation/ReservingDialog";

const ONE_MIN = 60000; // 1 min

const Container = styled(Stack)({
  width: "100%",
  height: "100%",
  flexGrow: 1,
  backgroundColor: "white",
  borderRadius: 4,
  overflow: "hidden",
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& *": {
    transition: "all 0.5s",
  },
  backgroundColor: theme.palette.primary.dark,
  "& .MuiTab-root": {
    color: "#EEEEEEAA",
    "&.Mui-selected": { color: "#FFFFFF", fontWeight: "bolder" },
  },

  "& .MuiTabs-indicator": {
    height: "4px",
    backgroundColor: theme.palette.primary.light,
  },
}));

function TabPanel(props) {
  const { children, ...other } = props;

  return (
    <div role="tabpanel" {...other}>
      <Box sx={{ width: "100%", height: "64vh", flex: 1, overflow: "scroll", background: "#FFF", mt: 1 }}>{children}</Box>
    </div>
  );
}

export default function QueueDashBoard() {
  const { useAllUser } = useUserService();
  const { useAllReservation, cancelReservation, deleteReservation } = useReservationService();
  const { useAllManipulateType } = useManipulateType();
  const { data: manipulateType } = useAllManipulateType();
  //顯示已結帳與否
  const [filterIsPaid, toggleFilterIsPaid] = useToggle(false);
  const [isPending, handleSubmit] = useSubmit(false);

  const [displayGroup, toggleDisplayGroup] = useToggle();

  const [reserveType, setReserveType] = useState("1");

  const [tabValue, setTabValue] = useState("");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [date, dateDispatch] = useDate();
  const { data: reservationData, mutate: mutateReservationData } = useAllReservation(
    {
      ...(reserveType === "0" ? {} : { reserveType }),
      sort: "start_time asc",
      date,
      isCancel: 0,
      limit: "zero",
    },
    {
      refreshInterval: ONE_MIN,
    },
  );
  const { data: usersData } = useAllUser({ query: new URLSearchParams({ isDoctor: 1, limit: "zero" }) });

  // const isDatePast = useMemo(() => isPast(new Date(date)), [date])
  const isDateToday = useMemo(() => getTodayDate() === date, [date]);
  const isDateFuture = useMemo(() => isFuture(new Date(date)), [date]);

  const filterReservationData = useMemo(() => {
    if (!reservationData) return [];
    if (!tabValue) return reservationData.list;

    return reservationData.list.filter(item => item.status === tabValue);
  }, [tabValue, reservationData]);

  const groupDoctorReservationData = useMemo(() => {
    if (!filterReservationData || !usersData) return [];

    const groupData = filterReservationData.reduce((acc, item) => {
      const employeeId = item?.medicalRecord?.userId || item?.employeeId;

      if (!acc[employeeId]) {
        acc[employeeId] = {
          list: [],
        };
      }
      acc[employeeId].list.push(item);
      return acc;
    }, {});

    const groupDoctorData = usersData.list.map(user => ({
      ...pick(user, ["userId", "realName", "departmentId", "salePointId"]),
      reservationList: groupData[user.userId]?.list || [],
    }));

    groupDoctorData.push({
      userId: null,
      realName: null,
      departmentId: null,
      salePointId: null,
      reservationList: groupData["null"]?.list || [],
    });

    groupDoctorData.sort((a, b) => b.reservationList.length - a.reservationList.length);

    return groupDoctorData;
  }, [usersData, filterReservationData]);

  //cancel 與 delete差別在於 delete會與舊系統同步
  const handleCancelReservation = useCallback(
    reservationId => handleSubmit(cancelReservation.bind(null, reservationId), mutateReservationData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleDeleteReservation = useCallback(
    reservationId => handleSubmit(deleteReservation.bind(null, reservationId), mutateReservationData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container>
      <Box>
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <Tab label="今日全覽" value="" />
          <Tab label="尚未報到" value="尚未報到" />
          <Tab label="候診中" value="候診中" />
          <Tab label="看診中" value="看診中" />
          {/* <Tab label="完診" value="完診" /> */}
          <Tab label="未結帳" value="未結帳" />
          <Tab label="已結帳" value="已結帳" />
        </StyledTabs>
      </Box>
      <TodayBrief date={date} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <ReservingDialog
            onAdd={mutateReservationData}
            button={
              <Button key="reservation-dialog-button" variant="contained" startIcon={<Icon type="add" />}>
                新增預約
              </Button>
            }
          />

          <TooltipIconButton type="left" title="往前一天" onClick={() => dateDispatch({ type: "backward" })} />
          <Input type="date" value={date} onChange={({ target }) => dateDispatch({ type: "setDate", payload: target.value })} />
          <TooltipIconButton type="right" title="往後一天" onClick={() => dateDispatch({ type: "forward" })} />

          <FormControlLabel
            checked={filterIsPaid}
            onChange={toggleFilterIsPaid}
            control={<Checkbox size="small" />}
            label={<Typography variant="body2">不顯示已結帳</Typography>}
          />

          <TooltipIconButton type="filter" title="切換顯示" onClick={toggleDisplayGroup} />
          <TooltipIconButton type="refresh" title="重新整理" onClick={() => mutateReservationData(null)} />
          <Typography variant="body2">
            <Counter delay={ONE_MIN} reservationData={reservationData} />
            秒前更新
          </Typography>
        </Stack>
        <ChipStatusHelper />
      </Stack>

      <TabPanel>
        <Stack direction="row" spacing={1} px={1} alignItems="center">
          <Button color="inherit" onClick={() => setReserveType("0")} variant={reserveType === "0" ? "outlined" : "text"}>
            ALL
          </Button>
          {manipulateType?.map(type => (
            <Button
              key={type.id}
              color="inherit"
              onClick={() => setReserveType(type.id)}
              variant={reserveType === type.id ? "outlined" : "text"}
            >
              {type.manipulateTypeName}
            </Button>
          ))}
          <Typography flex="1" textAlign="right">
            預約數：{reservationData?.totalCount ?? "-"}
          </Typography>
        </Stack>
        <QueueWaitList
          onMutate={mutateReservationData}
          // onCancel={handleCancelReservation}
          onCancel={handleDeleteReservation}
          isDisplayGroup={displayGroup}
          list={((displayGroup ? groupDoctorReservationData : filterReservationData) || []).filter(e =>
            filterIsPaid ? e.status !== "已結帳" : true,
          )}
          showCheckInAction={isDateToday || isDateFuture}
          isFuture={isDateFuture}
        />
      </TabPanel>
    </Container>
  );
}
